import { FollowType } from "@product/scmp-sdk";
import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { tracking } from "shared/data";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import type { trendingTopicTopic$key } from "scmp-app/queries/__generated__/trendingTopicTopic.graphql";

import {
  Container,
  Item,
  ItemContainer,
  StyledEntityLink,
  StyledHomeFollowButton,
  Title,
} from "./styles";
type Props = {
  className?: string;
  reference: trendingTopicTopic$key;
};

export const TrendingTopic: FunctionComponent<Props> = ({ className, reference: reference_ }) => {
  const data = useFragment(
    graphql`
      fragment trendingTopicTopic on Query
      @argumentDefinitions(trendingTopicsQueueName: { type: "String!" }) {
        trendingTopicsQueue: queue(filter: { name: $trendingTopicsQueueName }) {
          items(first: 5) {
            edges {
              node {
                ... on Topic {
                  ...entityLink
                  ...followButtonBase
                  name
                  entityId
                }
              }
            }
          }
        }
      }
    `,
    reference_,
  );

  if (data.trendingTopicsQueue?.items?.edges?.length === 0) return null;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.TrendingTopics,
      }}
    >
      <Container className={className}>
        <Title>TRENDING TOPICS</Title>
        <ItemContainer>
          {data.trendingTopicsQueue?.items?.edges?.map(({ node }) => (
            <Item key={node.entityId}>
              <StyledEntityLink reference={node}>{node.name}</StyledEntityLink>
              <StyledHomeFollowButton
                buttonVariant="square"
                reference={node}
                source="Trending Topics"
                type={FollowType.Topic}
              />
            </Item>
          ))}
        </ItemContainer>
      </Container>
    </BaseLinkContextProvider>
  );
};

TrendingTopic.displayName = "TrendingTopic";
