import { notEmpty, theme, useResponsive } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { oneSectionTopWidgetQueue$key } from "scmp-app/queries/__generated__/oneSectionTopWidgetQueue.graphql";

import {
  Container,
  Primary,
  PrimaryRest,
  Secondary,
  StyledContentItemHomeSecondary,
  StyledContentItemSectionTopLeadPrimary,
} from "./styles";

type Props = {
  preferTopicLabel?: boolean;
  primaryWithSection?: boolean;
  primaryWithTopic?: boolean;
  reference: oneSectionTopWidgetQueue$key;
  secondaryWithTopic?: boolean;
} & ContentItemRenderVariantProps;

export const SectionTopWidgetOne: FunctionComponent<Props> = ({
  className,
  onClick,
  preferTopicLabel,
  primaryWithSection,
  primaryWithTopic,
  reference,
  secondaryWithTopic,
}) => {
  const data = useFragment(
    graphql`
      fragment oneSectionTopWidgetQueue on QueueItemsEdge @relay(plural: true) {
        node {
          ... on Content {
            ...homeSecondaryContentItemContent
            ...sectionTopLeadPrimaryContentItemContent
          }
        }
      }
    `,
    reference,
  );
  const items = data?.map(({ node }) => node).filter(node => notEmpty(node)) ?? [];
  const isMobile = useResponsive(theme.breakpoints.only("mobile"), false);

  if (items.length === 0) return null;

  const [leading, one, two, ...rest] = items;

  return (
    <Container className={className}>
      <Primary>
        {leading && (
          <StyledContentItemSectionTopLeadPrimary
            onClick={(entityId, urlAlias) => onClick?.(entityId, urlAlias)}
            preferTopicLabel={preferTopicLabel}
            reference={leading}
            topicLinkVariant={{ type: "main" }}
            withComment
            withSection={primaryWithSection}
            withTopic={primaryWithTopic}
          >
            <PrimaryRest>
              {one && (
                <StyledContentItemHomeSecondary
                  onClick={(entityId, urlAlias) => onClick?.(entityId, urlAlias)}
                  reference={one}
                  skipHighlight
                  topicLinkVariant={{ type: "main" }}
                  withComment
                  withTopic={secondaryWithTopic}
                />
              )}
              {two && (
                <StyledContentItemHomeSecondary
                  onClick={(entityId, urlAlias) => onClick?.(entityId, urlAlias)}
                  reference={two}
                  skipHighlight
                  topicLinkVariant={{ type: "main" }}
                  withComment
                  withTopic={secondaryWithTopic}
                />
              )}
            </PrimaryRest>
          </StyledContentItemSectionTopLeadPrimary>
        )}
      </Primary>

      {rest?.length > 0 && (
        <Secondary>
          {rest
            ?.slice(0, 4)
            ?.map((node, index) => (
              <StyledContentItemHomeSecondary
                key={index}
                onClick={(entityId, urlAlias) => onClick?.(entityId, urlAlias)}
                reference={node}
                withComment={isMobile}
                withImage
                withTopic={secondaryWithTopic}
              />
            ))}
        </Secondary>
      )}
    </Container>
  );
};

SectionTopWidgetOne.displayName = "SectionTopWidgetOne";
